import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { graphql } from 'gatsby'
import { Layout, SEO, HeroHeading, Section } from '@components'
import { Page } from '@types'
import { Container } from '@bootstrap-styled/v4'

const AboutMePage: React.FC<Page<unknown>> = ({ data, location }) => {
  const { title, content } = data.wpgraphql.page
  const { transitionDurations } = useContext(ThemeContext)

  return (
    <Layout>
      <SEO title={title} pageUrl={location.pathname} />
      <Section hasBg verticalPadding>
        <Container>
          <div>
            <HeroHeading
              className="mb-5"
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            >
              {title}
            </HeroHeading>
          </div>
          {content && <div dangerouslySetInnerHTML={{ __html: content }} className="clearfix" />}
        </Container>
      </Section>
    </Layout>
  )
}

export default AboutMePage

export const pageQuery = graphql`
  query {
    wpgraphql {
      page(id: "15", idType: DATABASE_ID) {
        slug
        title(format: RENDERED)
        uri
        databaseId
        isFrontPage
        content(format: RENDERED)
      }
    }
  }
`
